
















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import MagazineCategories from '@/components/magazine/Categories.vue'
import MagazineStories from '@/components/magazine/Stories.vue'

export default defineComponent({
  name: 'MagazineArchive',
  components: {
    MagazineCategories,
    MagazineStories,
  },
  props: {},

  setup() {
    return {
      ...useGetters(['content']),
    }
  },
})
